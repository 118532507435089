import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getEmailProcessDataById(call, user_id, Mapper_Id) {
    return this.getData(`/em/email_process_data_byid?user_id=${user_id}&Mapper_Id=${Mapper_Id}`, call);
  },
  searchEmailProcessMapper(call, obj, showMessage) {
    return this.getData("/em/email_process_mapper_search", call, obj, showMessage);
  },
  getEmailProcessDropdown(call) {
    return this.getData(`/em/email_process_dropdown`, call);
  },
  getEmailDropdownData(call,input) {
    return this.getData(`/em/email_dropdown_data?input=${input}`, call);
  },

  getEmailList(call,string){
    return this.getData(`/em/email_dropdown_data?${string}`, call,"","",true);
  },
  postEmailUpsert(call,obj,showMessage){
    return this.getData('em/email_process_upsert',call,obj ,showMessage);
  },
  deleteEmailProcess(call,userId,mapperId,showMessage){
    return this.getData(`em/email_process_delete_byid?user_id=${userId}&Mapper_Id=${mapperId}`, call, "", showMessage);
  },


  async getData(url, call, obj,showMessage,showLoader) {
    if (!showLoader) {
      var LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    } else {
      LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
    }
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        if(showMessage)
          return {}
        else{
          return JSON.parse(res?.data?.body?.message);
        }
       
      }catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res.data.body.message == null) {
      LoaderDialog.visible = false;
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        if(showMessage)
          return {}
        else{
        return JSON.parse(res?.data?.message);
        }
       }catch (err) {
        console.log(err)
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
